<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'m-pesa-logo.svg'" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-errors="formErrors['amount']"
				@set-button-value="setButtonValue"
			/>

			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" @click="payWithRave()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'Deposit',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				promocode: null,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	async mounted() {
		const raveScript = document.createElement('script');
		raveScript.setAttribute('src', 'https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js');
		document.head.appendChild(raveScript);
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async createTransaction(amount, currency, mobilenumber, promocode) {
			const response = await apiClient.payFlutterweaveGeneric(this.walletId, mobilenumber, amount, currency, promocode);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async payWithRave() {
			this.loading = true;

			try {
				const currency = 'USD';
				const transaction = await this.createTransaction(
					this.form.amount,
					currency,
					this.mobilenumber,
					this.form.promocode
				);
				const email = `${transaction.customerId}@24domino.com`;

				const x = window.getpaidSetup({
					PBFPubKey: transaction.fwPubKey,
					customer_email: email,
					amount: this.form.amount,
					customer_phone: this.mobilenumber,
					payment_options: ['card'],
					currency,
					txref: transaction.id,
					meta: [{}],
					onclose: () => {
						this.loading = false;
						if (!this.paymentDone) {
							this.$router.push({
								path: '/payments/deposit/confirmation',
								query: {
									module: 'Deposit',
									status: 'error',
									method: 'generic',
								},
							});
						}
					},
					callback: (response) => {
						this.loading = false;
						this.paymentDone = true;

						if (response.tx.chargeResponseCode == '00' || response.tx.chargeResponseCode == '0') {
							Analytics.trackEvent({
								event: 'Deposit',
								value: this.form.amount,
								label: 'FlutterwaveGeneric',
							});

							this.$router.push({
								path: '/payments/deposit/confirmation',
								query: {
									status: 'success',
									method: 'generic',
								},
							});
						} else {
							this.$router.push({
								path: '/payments/deposit/confirmation',
								query: {
									status: 'error',
									method: 'generic',
								},
							});
						}

						x.close(); // close modal immediately after payment
					},
				});
			} catch (e) {
				console.error(e);
				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
			}
		},
	},
	computed: {
		...mapGetters(['getUser']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('flutterwave');
		},
		mobilenumber() {
			return this.getUser.mobilenumber;
		},
	},
};
</script>
<style scoped>
button.btn-submit {
	margin-top: 35px;
}
</style>
